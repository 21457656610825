:root {
  color-scheme: light;
}

body {
  margin: 0;
  font-family: "Ebrima", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-smoothing: grayscale;
  background-color: #f9f9f9;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth; /* Zorg voor vloeiend scrollen */
}

.App {
  overflow-y: auto; /* Zorg dat de app-container scrollbaar is */
  height: 100vh;
}

p{
  font-family: 'Ebrima', Arial, Helvetica, sans-serif;
  font-size: 12px !important;

}

h1{
  font-family: 'Ebrima';
  font-size: 24px !important;
}

.boldText {
  font-size: 18;
  font-weight: bold;
  color: #064e95;
}

.cookie-policy-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  max-width: 800px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.cookie-policy-container h1,
.cookie-policy-container h2 {
  color: #2b373b;
}

.cookie-policy-container ul {
  list-style: disc;
  margin-left: 20px;
}

.wrapper {
  width: 100%;
  min-height: 100svh;
  display: flex;
}

.wrapper__container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex: 1 1 auto;
}

.wrapper__container-inner {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.jc-between {
  justify-content: space-between;
}

.down-arrow,
.up-arrow {
  font-size: 3rem;
  cursor: pointer;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 576px) {
  .down-arrow,
  .up-arrow {
    font-size: 2rem;
  }
}
