.aboutme-container {
  font-family: "ebrima";
  background-image: url("/Thymen.png"); /* Zorg ervoor dat je het juiste pad naar je afbeelding gebruikt */
  background-size: cover;
  background-position: center;
}

.aboutme-container {
/*   height: 100vh;
 */  font-family: "ebrima";
  background-image: url("/Thymen.png"); /* Zorg ervoor dat je het juiste pad naar je afbeelding gebruikt */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
}
/* #werkBlok{
  margin-top: -100px !important;
} */

/* .header{
  padding-bottom: -100px;
} */

/* .header{
  position: absolute;
  top: 10vw;
} */

.aboutme-blok-1 {
  padding: 0;
  margin: 0;
  /* height: 10svh; */
}
.aboutme-blok-2 {
  padding: 0;
  margin: 0;
  /* height: 70svh; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.aboutme-blok-3 {
  padding: 0;
  margin: 0;
  /* height: 20svh; */
}

.boldText {
  font-size: large;
  font-weight: bold;
  color: #064e95;
}
ul{
text-align: center;
align-items:center;
}

li {
  align-self: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

ul li::marker {
  color: #064e95;

}

.werkervaring {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.werkervaring li:last-child {
  margin-bottom: 0;
}

.werkervaringItem {
  align-items: center;
}

.stip {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff6700;
  border-radius: 50%;
  position: relative;
  left: -100vw; /* Begin volledig buiten het scherm links */
  transition: left 1s ease-in; /* Sneller naar rechts schuiven */
  margin-right: 10px; /* Ruimte tussen de stip en de tekst */
}

.stip.visible {
  left: 0; /* Schuif naar de normale positie naast de tekst */
}

.navigation-arrows {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Nieuwe styling voor de blauwe stippen */
@keyframes fadeInOut {
  0%,
  50% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}

.orange-stip, .orange-stip2, .orange-stip3, .orange-stip4, .orange-stip5, .orange-stip6  {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ff6700; /* Blauwe kleur voor de stippen */
  border-radius: 50%;
  margin-right: 10px;
  opacity: 0;
  animation: fadeInOut 9s ease-in-out infinite;
}
 

.oranjeTekst{

  color: rgb(255, 255, 255) !important;
/*   font-weight: bold;
 */}

#oranjeTekst{
  color: #ff6700;
}

.orange-stip:nth-child(1) {
  animation-delay: 0s;
}

.orange-stip:nth-child(2) {
  animation-delay: 1s;
}

.orange-stip:nth-child(3) {
  animation-delay: 2s;
}

@media only screen and (max-width: 600px) {
  .orange-stip {
    display: inline-block;
  }
}
.orange-stip2:nth-child(1) {
  animation-delay: 1s;
}

.orange-stip2:nth-child(2) {
  animation-delay: 1.15s;
}

.orange-stip2:nth-child(3) {
  animation-delay: 2.15s;
}

@media only screen and (max-width: 600px) {
  .orange-stip2 {
    display: inline-block;
  }
}
.orange-stip3:nth-child(1) {
  animation-delay: 2s;
}

.orange-stip3:nth-child(2) {
  animation-delay: 1.30s;
}

.orange-stip3:nth-child(3) {
  animation-delay: 2.30s;
}

@media only screen and (max-width: 600px) {
  .orange-stip3 {
    display: inline-block;
  }
}
.orange-stip4:nth-child(1) {
  animation-delay: 3;
}

.orange-stip4:nth-child(2) {
  animation-delay: 1.33s;
}

.orange-stip4:nth-child(3) {
  animation-delay: 2.33s;
}

@media only screen and (max-width: 600px) {
  .orange-stip4 {
    display: inline-block;
  }
}
.orange-stip5:nth-child(1) {
  animation-delay: 4s;
}

.orange-stip5:nth-child(2) {
  animation-delay: 1.33s;
}

.orange-stip5:nth-child(3) {
  animation-delay: 2.33s;
}

@media only screen and (max-width: 600px) {
  .orange-stip {
    display: inline-block;
  }
}
.orange-stip6:nth-child(1) {
  animation-delay: 5s;
}

.orange-stip6:nth-child(2) {
  animation-delay: 1.33s;
}

.orange-stip6:nth-child(3) {
  animation-delay: 2.33s;
}

@media only screen and (max-width: 600px) {
  .orange-stip6 {
    display: inline-block;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 576px) {
  .werkervaring {
    padding: 0;
    padding-left: 15px;
    align-items: start;
    max-width: 100%;

    & li {
      white-space: normal;
    }
  }
}
