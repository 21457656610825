.cv-container {
  font-family: "ebrima";
  max-width: 100vw; /* Zorgt ervoor dat de container niet breder wordt dan het scherm */
  overflow-x: hidden; /* Verberg eventuele horizontale scrollbalken */
}

.wrapper__container {
  max-width: 100%;
  box-sizing: border-box; /* Zorgt ervoor dat padding en borders worden meegeteld in de breedte */
  padding: 0 15px; /* Voeg padding toe om wat ruimte te creëren binnen het scherm */
}

.CV-blok-1 {
  padding: 0;
  margin: 0;
}

.CV-blok-2 {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1 1 auto; /* Zorgt ervoor dat de inhoud krimpt om binnen de beschikbare ruimte te passen */
}

.CV-blok-3 {
  padding: 0;
  margin: 0;
}

ul {
  text-align: center;
  align-items: center;
}

li {
  align-self: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

ul li::marker {
  color: #064e95;
}

.werkervaring {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.werkervaring li:last-child {
  margin-bottom: 0;
}

.werkervaringItem {
  align-items: center;
}

.stip {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff6700;
  border-radius: 50%;
  position: relative;
  left: -100vw; /* Begin volledig buiten het scherm links */
  transition: left 1s ease-in; /* Sneller naar rechts schuiven */
  margin-right: 10px; /* Ruimte tussen de stip en de tekst */
}

.stip.visible {
  left: 0; /* Schuif naar de normale positie naast de tekst */
}

.navigation-arrows {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 576px) {
  .cv-container {
    padding: 0;
    font-size: 14px; /* Pas de algemene tekstgrootte aan voor kleinere schermen */
  }

  .CV-blok-1 h1 {
    font-size: 1.5em; /* Maak de header iets kleiner */
  }

  .werkervaring {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .werkervaring li {
    font-size: 0.9em; /* Maak de tekst in de lijst iets kleiner */
  }
}
