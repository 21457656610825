.contact-container {
  background-color: #ffffff;
  font-family: "ebrima";
}
.contact-blok-1 {
  padding: 0;
  margin: 0;
  /* height: 10svh; */
}
.contact-blok-2 {
  padding: 0;
  margin: 0;
  /* height: 70svh; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.contact-blok-3 {
  padding: 0;
  margin: 0;
  /* height: 20svh; */
}
/* .header {
  position: absolute;
  top: 10vw;
} */

/* Toegevoegde CSS voor de oranje lijn */
.orange-line {
  height: 3px;
  background-color: #ff6700; /* Oranje kleur */
  width: 64vw;
}

/* Toegevoegde CSS voor de oranje stippen */
.orange-dots-container {
  display: flex;
  justify-content: center;
}

.orange-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff6700; /* Oranje kleur */
  border-radius: 50%;
  margin: 0 5px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.button-container button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: solid 1px;
  border-color: #064e95;
  background-color: white;
  color: #064e95;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-family: "Ebrima";
  font-weight: bold;
}

.button-container button:hover {
  background-color: #0038a8;
  color: white;
}

.button-container button svg {
  margin-right: 5px;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: row;
  }
  .button-container button {
    flex: 1 1 45%;
    margin: 5px;
  }
}
/* 
.navigation-arrows {
  position: absolute;
  bottom: 16vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
} */

/* @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
} */
