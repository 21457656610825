.cv-container {
  font-family: "ebrima";
}
/* #werkBlok{
  margin-top: -100px !important;
} */

/* .header{
  padding-bottom: -100px;
} */

/* .header{
  position: absolute;
  top: 10vw;
} */

.CV-blok-1 {
  padding: 0;
  margin: 0;
  /* height: 10svh; */
}
.CV-blok-2 {
  padding: 0;
  margin: 0;
  /* height: 70svh; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.CV-blok-3 {
  padding: 0;
  margin: 0;
  /* height: 20svh; */
}

.boldText {
  font-size: large;
  font-weight: bold;
  color: #064e95;
}
ul{
text-align: center;
align-items:center;
}

li {
  align-self: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

ul li::marker {
  color: #064e95;

}

.werkervaring {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.werkervaring li:last-child {
  margin-bottom: 0;
}

.werkervaringItem {
  align-items: center;
}

.stip2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff6700;
  border-radius: 50%;
  position: relative;
  /* left: -100vw; 
  transition: left 1s ease-in;  */
  margin-right: 10px; 
  opacity: 0;
  animation: fadeInOut 6s ease-in-out infinite;
}

/* .stip.visible {
  left: 0; 
} */

.navigation-arrows {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 576px) {
  .werkervaring {
    padding: 0;
    padding-left: 15px;
    align-items: start;
    max-width: 100%;

    & li {
      white-space: normal;
    }
  }
}
