


.home-container {
  font-family: "ebrima";
  background-image: url("/afbeelding.png"); /* Zorg ervoor dat je het juiste pad naar je afbeelding gebruikt */
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
}

.blue-line-home {
  width: 0px; /* Vaste lengte van de lijn */
  height: 3px;
  background-color: #ff6700;
  position: relative;
  right: -48vw; /* Begin volledig buiten het scherm rechts */
  transition: right 1s ease-in; /* Sneller naar links schuiven */
  margin-bottom: 20px;
}

.blue-line-home.visible {
  right: 0; /* Schuif naar de normale positie onder de tekst */
  width: 50px;
}

.fade-in {
  opacity: 0;
  transition: opacity 3s ease-in !important;
}

.fade-in.visible {
  opacity: 1;
}

#cv-header {
  font-size: x-large;
}

#cv-header-span {
  font-size: xx-large;
}

/* .navigation-arrows {
  position: absolute;
  bottom: 10vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
} */

/* @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
} */
