.cv2-container {
  font-family: "ebrima";
  background-image: url("/afbeelding2.png");
  background-size: cover;
  background-position: calc(50% - 155px) center;
  color: white;
}

.CV2-blok-1 {
  padding: 0;
  margin: 0;
}

.CV2-blok-2 {
  padding: 0;
  margin: 0;
  margin-top: auto; /* Zorgt ervoor dat blok 2 naar beneden wordt gedrukt */
}

.CV2-blok-3 {
  padding: 0;
  margin: 0;
}

.CV2Blok1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper__container-inner.jc-adjusted {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.orange-line {
  height: 3px;
  background-color: #ff6700; /* Oranje kleur */
  margin-top: 10px;
  width: 64vw;
}

.headerCV2 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; /* Verminderd de ruimte boven de tekst */
}

.opleidingen {
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  list-style: none;
  align-items: center;
}

.opleidingenItem {
  text-align: center;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.visible {
  opacity: 1;
}

.blue-line2 {
  height: 3px;
  background-color: #ff6700;
  width: 60vw;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
  align-items: center;
}

@keyframes fadeInOut {
  0%,
  50% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}

.blue-stip {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #064e95; /* Blauwe kleur voor de stippen */
  border-radius: 50%;
  margin-right: 10px;
  opacity: 0;
  animation: fadeInOut 6s ease-in-out infinite;
}

.blue-stip:nth-child(1) {
  animation-delay: 0s;
}

.blue-stip:nth-child(2) {
  animation-delay: 1s;
}

.blue-stip:nth-child(3) {
  animation-delay: 2s;
}

@media only screen and (max-width: 600px) {
  .blue-stip {
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .additional-info {
    margin-top: 0;
  }

  .headerCV2 {
    margin-top: 10px;
  }
}
